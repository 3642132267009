import React from 'react';
import clsx from 'clsx';

import styles from './text.module.scss';

type TextSize = 'large' | 'normal' | 'small';
type FontWeight = 'regular' | 'medium' | 'semibold' | 'bold';

interface TextProps {
  size?: TextSize;
  weight?: FontWeight;
  children: React.ReactNode;
  as?: keyof JSX.IntrinsicElements;
  className?: string;
  style?: React.CSSProperties;
  color?: 'light' | 'normal' | 'dark';
}

export const Text: React.FC<TextProps> = ({
  size = 'normal',
  weight = 'regular',
  children,
  as = 'p',
  className,
  style,
  color = 'normal'
}) => {
  const Tag = as;

  return (
    <Tag
      className={clsx(styles.text, styles[size], styles[weight], styles[color], className)}
      style={style}
    >
      {children}
    </Tag>
  );
};

export default Text;
