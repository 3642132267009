import { useFormikContext } from 'formik';
import { Actions } from 'modules/myTodos/components/actions';
import { ClientSearch } from 'modules/myTodos/components/actions/clientSearch';
import ColorPicker from 'modules/myTodos/components/actions/colorPicker/colorPicker';
import EventForm from 'modules/myTodos/components/actions/eventForm/eventForm';
import { GoalPicker } from 'modules/myTodos/components/actions/goalPicker';
import { LocationForm } from 'modules/myTodos/components/actions/locationForm';
import { NotesForm } from 'modules/myTodos/components/actions/notesForm';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { mapDates } from 'modules/myTodos/utils/date';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useRef } from 'react';
import { getGoals, patchUpdateTodoStatus, putUpdateTodoDraft } from 'store/myTodos/myTodosActions';
import {
  clearClients,
  clearGoals,
  setHasOpenedPopper,
  Todo,
  updateTodo
} from 'store/myTodos/myTodosSlice';
import { getLsUserRoleObject } from 'modules/networkTools/localStorageTokens';
import { PERMISSION } from 'utils/protectedRoutes/userRolesAndPermissionTypes';
import { useIsOnlyVisibleToUserWithPermissions } from 'utils/hooks/useIsOnlyVisibleTo';
import { getTodos } from 'store/roadmaps/roadmapsActions';
import { notifyUserError } from 'utils/notifications';

import actionsStyle from '../actions/actions.module.scss';
import { TodoInitialValues } from './todo';
import { AbandonButton } from '../abandonButton';
import { ReactivateButton } from '../reactivateButton';
import { ClientButton } from '../clientButton';

type Props = {
  todo: Todo;
};

const useAutosaveForm = ({ originalId }: { originalId?: number }) => {
  const pendingAutosaveRef = useRef<{ abort: () => void } | null>(null);
  const clientId = useAppSelector((state) => state.clients.client.data?.id);
  const dispatch = useAppDispatch();
  const { values, dirty, isSubmitting } = useFormikContext<TodoInitialValues>();

  const autosaveForm = async (
    newValues: TodoInitialValues,
    cId: string | undefined,
    _isSubmitting: boolean,
    _pendingAutosaveRef: React.MutableRefObject<{ abort: () => void } | null>
  ) => {
    const { date, startDateTime, endDateTime } = mapDates({
      date: newValues.date,
      endDate: newValues.endDate,
      startTime: newValues.startTime,
      endTime: newValues.endTime,
      hasDifferentEndDate: newValues.hasDifferentEndDate
    });

    if (_isSubmitting) {
      return;
    }

    const promise = dispatch(
      putUpdateTodoDraft({
        id: originalId ?? undefined,
        title: newValues.title,
        clientId: newValues.client?.id,
        goalId: newValues.goalId,
        note: newValues.note,
        location: newValues.location,
        color: newValues.color,
        startDateTime,
        endDateTime,
        date,
        status: 'ACTIVE',
        creationContext: 'MY_TODOS'
      })
    );
    _pendingAutosaveRef.current = promise;
    await promise;
    if (cId) {
      dispatch(getTodos({ clientId: cId }));
    }
  };

  const debounceAutosave = useCallback(debounce(autosaveForm, 400), []);

  useEffect(() => {
    if (isSubmitting) {
      pendingAutosaveRef.current?.abort();
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (dirty) {
      dispatch(updateTodo({ id: originalId ?? undefined, isAutosaving: true }));
      debounceAutosave(values, clientId, isSubmitting, pendingAutosaveRef);
    }
  }, [values, isSubmitting]);
};

const TodoActions = ({ todo }: Props) => {
  const clientId = useAppSelector((state) => state.clients.client.data?.id);
  const dispatch = useAppDispatch();
  const { userId } = getLsUserRoleObject();
  const { values, errors, dirty } = useFormikContext<TodoInitialValues>();
  const canSeeClientTodos = useIsOnlyVisibleToUserWithPermissions([PERMISSION.VIEW_CLIENT_TODOS]);
  const status = todo.status;
  const activeGoals = todo.goals?.filter((goal) => goal.status === 'ACTIVE') ?? [];

  useAutosaveForm({ originalId: todo.id });

  const onActionClick = async ({ type }: { type: string }) => {
    if (!todo.id) return;

    if (type === 'abandon') {
      await dispatch(patchUpdateTodoStatus({ id: todo.id, status: 'ABANDONED' }));
    }
    if (type === 'reactivate') {
      if (todo.goalStatus === 'COMPLETED' || todo.goalStatus === 'ABANDONED') {
        notifyUserError('To-do cannot be reactivated because its Goal is not active.');

        return;
      }
      await dispatch(patchUpdateTodoStatus({ id: todo.id, status: 'ACTIVE' }));
    }
    if (clientId) {
      dispatch(getTodos({ clientId }));
    }
  };

  const hasDraft = Boolean(todo.draft);

  const isTodoBeingEditedByAnotherUser = hasDraft && todo.draft?.userId !== userId;
  const isTodoBeingEditedInRoadmaps = todo.draft?.creationContext === 'ROADMAPS';

  const onOpen = ({ name }: { name: string }) => {
    dispatch(setHasOpenedPopper({ opened: true }));
  };

  const onClose = ({ name }: { name: string }) => {
    if (name === 'client') {
      dispatch(clearClients());
    }
    dispatch(setHasOpenedPopper({ opened: false }));
  };

  useEffect(() => {
    if (values.client?.id) {
      dispatch(getGoals({ clientId: values.client.id, todoId: todo.id }));
    } else {
      dispatch(clearGoals({ todoId: todo.id }));
    }
  }, [values.client]);

  const actions = [
    {
      name: 'client',
      icon: <ClientButton />,
      popover: (
        <ClientSearch
          disabled={
            status === 'ABANDONED' ||
            status === 'COMPLETED' ||
            isTodoBeingEditedByAnotherUser ||
            isTodoBeingEditedInRoadmaps
          }
        />
      ),
      show: canSeeClientTodos,
      hasValues: values.client != null
    },
    {
      name: 'goal',
      icon: 'landscape',
      popover: (
        <GoalPicker
          disabled={
            status === 'ABANDONED' ||
            status === 'COMPLETED' ||
            isTodoBeingEditedByAnotherUser ||
            isTodoBeingEditedInRoadmaps
          }
          goals={status === 'ABANDONED' || status === 'COMPLETED' ? todo.goals ?? [] : activeGoals}
        />
      ),
      show: values.client != null,
      hasValues: values.goalId != null
    },
    {
      name: 'event',
      icon: 'event',
      popover: (
        <EventForm
          disabled={
            status === 'ABANDONED' ||
            status === 'COMPLETED' ||
            isTodoBeingEditedByAnotherUser ||
            isTodoBeingEditedInRoadmaps
          }
        />
      ),
      hasValues: [values.date, values.endDate, values.startTime, values.endTime].some(
        (value) => value != null && value !== ''
      ),
      hasError: [errors.date, errors.endDate, errors.startTime, errors.endTime].some(
        (value) => value != null
      ),
      show: true
    },
    {
      name: 'notes',
      icon: 'notes',
      popover: (
        <NotesForm
          disabled={
            status === 'ABANDONED' ||
            status === 'COMPLETED' ||
            isTodoBeingEditedByAnotherUser ||
            isTodoBeingEditedInRoadmaps
          }
        />
      ),
      hasValues: values.note != null && values.note !== '',
      show: true
    },
    {
      name: 'location',
      icon: 'location_on',
      popover: (
        <LocationForm
          disabled={
            status === 'ABANDONED' ||
            status === 'COMPLETED' ||
            isTodoBeingEditedByAnotherUser ||
            isTodoBeingEditedInRoadmaps
          }
        />
      ),
      hasValues: values.location != null && values.location !== '',
      show: true
    },
    {
      name: 'color',
      icon: (
        <div className={actionsStyle.colorPicker}>
          <div className={actionsStyle.color} style={{ backgroundColor: values.color }} />
        </div>
      ),
      popover: (
        <ColorPicker
          disabled={
            status === 'ABANDONED' ||
            status === 'COMPLETED' ||
            isTodoBeingEditedByAnotherUser ||
            isTodoBeingEditedInRoadmaps
          }
        />
      ),
      show: true
    },
    {
      name: 'abandon',
      icon: <AbandonButton style={{ color: values.color }} />,
      show: status === 'ACTIVE',
      disabled: dirty || hasDraft
    },
    {
      name: 'reactivate',
      icon: <ReactivateButton style={{ color: values.color }} />,
      show: status === 'ABANDONED'
    }
  ];

  return (
    <Actions
      actions={actions}
      color={values.color}
      onActionClick={onActionClick}
      onOpen={onOpen}
      onClose={onClose}
    />
  );
};

export default TodoActions;
